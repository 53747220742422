import { Dialog, DialogActions, DialogTitle } from "@mui/material";
import { QueryProgress } from "core/types";
import RSButton from "ds_legacy/components/RSButton";
import { useTranslations } from "translations";
import { CONFIRM_SEND_FAX } from ".";
import { FilterReasons } from "./shared";

export const ConfirmSendFaxModal = ({
  filterReasons,
  handleConfirm,
  onClose,
  open,
  providerName,
  queryProgress,
}: {
  filterReasons: string;
  handleConfirm: () => void;
  onClose: () => void;
  open: boolean;
  providerName: string;
  queryProgress: QueryProgress;
}) => {
  const translations = useTranslations();
  return (
    <Dialog data-testid={CONFIRM_SEND_FAX} open={open}>
      <DialogTitle>
        {translations.search.searchPageTables.modals.sendFax.titleConfirmation({
          providerName,
        })}
      </DialogTitle>
      <FilterReasons filterReasons={filterReasons} />
      <DialogActions disableSpacing>
        <RSButton
          color="primary"
          id="cancel"
          loading="na"
          onClick={onClose}
          variant="text"
        >
          {translations.search.searchPageTables.modals.sendFax.buttonCancel}
        </RSButton>
        <RSButton
          color="primary"
          id="confirm"
          inverted
          loading={queryProgress}
          onClick={handleConfirm}
          variant="text"
        >
          {translations.search.searchPageTables.modals.sendFax.buttonSend}
        </RSButton>
      </DialogActions>
    </Dialog>
  );
};
