import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import { QueryProgress } from "core/types";
import RSButton from "ds_legacy/components/RSButton";
import { useTranslations } from "translations";
import { CONFIRM_SEND } from ".";
import { FilterReasons } from "./shared";

export const ConfirmSendModal = ({
  filterReasons,
  handleConfirm,
  onClose,
  open,
  queryProgress,
}: {
  filterReasons: string;
  handleConfirm: () => void;
  onClose: () => void;
  open: boolean;
  queryProgress: QueryProgress;
}) => {
  const translations = useTranslations();
  return (
    <Dialog data-testid={CONFIRM_SEND} open={open}>
      <DialogTitle>
        {translations.search.searchPageTables.modals.contactAnyway.title}
      </DialogTitle>
      <DialogContent>
        <FilterReasons filterReasons={filterReasons} />
        <DialogContentText>
          {translations.search.searchPageTables.modals.contactAnyway.subtitle}
        </DialogContentText>
      </DialogContent>
      <DialogActions disableSpacing>
        <RSButton
          color="primary"
          id="cancel"
          loading="na"
          onClick={onClose}
          variant="text"
        >
          {translations.actions.cancel}
        </RSButton>
        <RSButton
          color="primary"
          id="confirm"
          inverted
          loading={queryProgress}
          onClick={handleConfirm}
          variant="text"
        >
          {translations.actions.confirm}
        </RSButton>
      </DialogActions>
    </Dialog>
  );
};
