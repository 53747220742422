import { DialogContentText } from "@mui/material";
import { CUSTOM_BLACK } from "ds_legacy/materials/colors";
import { HorizontalLayout } from "ds_legacy/materials/layouts";
import { padding, sizing } from "ds_legacy/materials/metrics";
import { FONT_WEIGHT_BOLD } from "ds_legacy/materials/typography";
import { useTranslations } from "translations";

export const FilterReasons = ({ filterReasons }: { filterReasons: string }) => {
  const translations = useTranslations();

  if (!filterReasons) return null;

  return (
    <HorizontalLayout
      gap={sizing(1)}
      style={{ color: CUSTOM_BLACK }}
      padding={padding(0, 0, 2)}
    >
      <DialogContentText style={{ fontWeight: FONT_WEIGHT_BOLD }}>
        {translations.search.searchPageTables.columnNames.filterReason}
        {translations.general.colon}
      </DialogContentText>
      <DialogContentText>{filterReasons}</DialogContentText>
    </HorizontalLayout>
  );
};
