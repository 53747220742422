import { Typography } from "@mui/material";
import { useTheme } from "@mui/styles";
import {
  COLUMN_TYPE_FILES_CATEGORY,
  COLUMN_TYPE_FILES_DATE_AND_SOURCE,
  COLUMN_TYPE_FILES_FILE_NAME,
  COLUMN_TYPE_FILES_SHARED_WITH,
  COLUMN_TYPE_FILES_UNAVAILABLE_REASON,
  FILE_SHARE_MODE_NONE,
} from "core/consts";
import { getDateValue, getLocale } from "core/model/utils/dates";
import {
  ONTOLOGY_FILE_CATEGORY,
  ONTOLOGY_FILE_ERROR,
} from "core/model/utils/ontologies";
import { useGetOntology } from "core/model/utils/ontologies/hooks";
import { LockSecurePin } from "ds_legacy/components/Icons/SecurePin";
import InfoChip from "ds_legacy/components/InfoChip";
import Tooltip from "ds_legacy/components/Tooltip";
import { ACCENT_DARK_COLOR, GREY_600 } from "ds_legacy/materials/colors";
import { VerticalLayout } from "ds_legacy/materials/layouts";
import { dp, padding } from "ds_legacy/materials/metrics";
import {
  Body,
  FONT_SIZE_12,
  FONT_SIZE_14,
  FONT_WEIGHT_MEDIUM,
  FONT_WEIGHT_REGULAR,
  LINE_HEIGHT_18,
  LINE_HEIGHT_20,
} from "ds_legacy/materials/typography";
import { getSharedWith } from "dsl/ecosystems/PatientFiles/utils";
import { blurStyle } from "dsl/organisms/Infos/PatientInformation/shared";
import { AlertTriangleIcon, CircleAlertIcon } from "lucide-react";
import { CSSProperties, useEffect, useState } from "react";
import styled from "styled-components";
import { useLocale, useTranslations } from "translations";
import { CellProps, ValueWithToolTip, getDefaultValue } from ".";
import { DefaultCellBodyText } from "../styles";

const StyledIconAndTextWrapper = styled.div<{ gap?: CSSProperties["gap"] }>`
  width: 100%;
  display: flex;
  gap: ${({ gap }) => gap ?? padding(1)};
  align-items: center;
`;

const StyledIconWrapper = styled.span`
  line-height: ${dp(20)};
  font-size: ${dp(14)};
  height: ${dp(16)};
  width: ${dp(16)};
`;

export function CategoryValue({
  category,
}: CellProps<typeof COLUMN_TYPE_FILES_CATEGORY>) {
  const getOntology = useGetOntology();

  return (
    <InfoChip
      label={getOntology({
        key: category,
        type: ONTOLOGY_FILE_CATEGORY,
        useShortValues: true,
      })}
      tooltip={getOntology({
        key: category,
        type: ONTOLOGY_FILE_CATEGORY,
      })}
    />
  );
}

export function SharedWithValue({
  careseekerId,
  share_mode,
}: CellProps<typeof COLUMN_TYPE_FILES_SHARED_WITH>) {
  const [value, setValue] = useState<string>("");
  const translations = useTranslations();

  useEffect(() => {
    if (share_mode) {
      const sharedWith = getSharedWith({
        share_mode,
        translations,
      });
      setValue(sharedWith);
    }
  }, [share_mode, translations, careseekerId]);

  if (share_mode === FILE_SHARE_MODE_NONE) {
    return <InfoChip label={value} variant="outlined" color="info" />;
  }

  return <ValueWithToolTip value={value} />;
}

export function UnavailableReason({
  unavailable_reason,
}: CellProps<typeof COLUMN_TYPE_FILES_UNAVAILABLE_REASON>) {
  const getOntology = useGetOntology();

  return (
    <StyledIconAndTextWrapper>
      <Typography sx={{ lineHeight: dp(20), fontSize: dp(14), flex: 1 }}>
        {getOntology({ type: ONTOLOGY_FILE_ERROR, key: unavailable_reason })}
      </Typography>
      <StyledIconWrapper>
        <AlertTriangleIcon style={{ color: ACCENT_DARK_COLOR }} size={16} />
      </StyledIconWrapper>
    </StyledIconAndTextWrapper>
  );
}

export function FileName({
  isNewVersionAvailable,
  shouldBlur,
  value,
}: CellProps<typeof COLUMN_TYPE_FILES_FILE_NAME>) {
  const translations = useTranslations();
  const formattedValue = getDefaultValue(value);
  const theme = useTheme();

  return (
    <VerticalLayout width="100%">
      <StyledIconAndTextWrapper>
        <Tooltip title={formattedValue}>
          <DefaultCellBodyText style={{ ...(shouldBlur ? blurStyle : {}) }}>
            {formattedValue}
          </DefaultCellBodyText>
        </Tooltip>

        <StyledIconWrapper>
          <LockSecurePin style={{ color: GREY_600, fontSize: FONT_SIZE_14 }} />
        </StyledIconWrapper>
      </StyledIconAndTextWrapper>
      {isNewVersionAvailable && (
        <StyledIconAndTextWrapper gap={padding(0.5)}>
          <CircleAlertIcon
            size={16}
            style={{ color: theme.palette.error.dark }}
          />
          <DefaultCellBodyText
            fontSize={FONT_SIZE_12}
            lineHeight={LINE_HEIGHT_18}
            color={theme.palette.error.dark}
          >
            {
              translations.fileSection.fileListComponent
                .descriptionNewFileVersionAvailable
            }
          </DefaultCellBodyText>
        </StyledIconAndTextWrapper>
      )}
    </VerticalLayout>
  );
}
export function DateAndSource({
  source,
  uploadDate,
}: CellProps<typeof COLUMN_TYPE_FILES_DATE_AND_SOURCE>) {
  const locale = useLocale();
  const currentLocale = getLocale(locale);
  const formattedDate = getDateValue(uploadDate, currentLocale);

  return (
    <VerticalLayout overflow="visible" width="auto">
      <Body
        fontSize={FONT_SIZE_14}
        fontWeight={FONT_WEIGHT_REGULAR}
        lineHeight={LINE_HEIGHT_20}
        margin="0"
      >
        {formattedDate}
      </Body>
      <Body
        fontSize={FONT_SIZE_12}
        fontWeight={FONT_WEIGHT_MEDIUM}
        lineHeight={LINE_HEIGHT_18}
        margin="0"
      >
        {source}
      </Body>
    </VerticalLayout>
  );
}
